

export const getAddBillServiceReducer=(state={}, action)=>{

    switch(action.type)
    {
        case 'ADD_BILLSERVICE_REQUEST': return{
            loading:true,
            ...state
        }
        case 'ADD_BILLSERVICE_SUCCESS': return{
            loading:false,
            success:true
        }
        case 'ADD_BILLSERVICE_FAILED': return{
            loading:false,
            error:action.payload
        }
        
        case 'ALL_BILLSERVICE_CLEAR': return{
            loading:false,
            success:false
        }
        
        default : return state
    }
}

export const getAllBillServiceReducer=(state={billservice:[]}, action)=>{

    switch(action.type)
    {
        case 'GET_BILLSERVICE_REQUEST': return{
            loading:true,
            ...state
        }
        case 'GET_BILLSERVICE_SUCCESS': return{
            loading:false,
            billservice:action.payload
        }
        case 'GET_BILLSERVICE_FAILED': return{
            loading:false,
            error:action.payload
        }
        default : return state
    }
}