
import React,{useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {logoutUser} from '../../redux/actions/userActions';
import {useHistory,Link,useLocation,NavLink} from "react-router-dom";  
import logo from '../../assets/logo.png'

import './AdminNavbar.css';

const AdminNavbar = () => {
    const [val, setVal] = useState('')
    const [navLinkOpen, navLinkToggle] =  useState(false);
    const history = useHistory();
    const location = useLocation()
    const handleNavlinkToggle = () =>{
        navLinkToggle(!navLinkOpen)         
    }

    const dispatch = useDispatch()
    const logOut = ()=>{    
        dispatch(logoutUser()) .then(()=>{           
            history.push("/login");
        })     
     }
   
    const renderClasses = () =>{
        let classes = "navlinks";
        if(navLinkOpen){
            classes += " active"
        }
        return classes;
    }

    return ( 
        <div className='navbar' id='top'>
            <nav>
                <div className='logo'>
                      <img src={logo} style={{height:'5rem',width:'8rem',backgroundColor:'#fff'}}/>                     
                </div>
             
                <ul className={renderClasses()}>
                     <NavLink onClick={handleNavlinkToggle}  to="/sales" smooth="true"  duration={500} exact activeClassName="click"> <h4 onClick={()=>{handleNavlinkToggle('sales')}}>Bill(Products)</h4> </NavLink>
                     <NavLink onClick={handleNavlinkToggle} to="/billservice" smooth="true"  duration={500}  exact activeClassName="click"> <h4>Bill(Service)</h4> </NavLink>
                     <NavLink onClick={handleNavlinkToggle} to="/customers" smooth="true"  duration={500}  exact activeClassName="click"> <h4>Customers</h4> </NavLink>
                     <NavLink onClick={handleNavlinkToggle} to="/products" smooth="true"  duration={500}  exact activeClassName="click"> <h4>Products</h4> </NavLink>
                     <NavLink onClick={handleNavlinkToggle} to="/services" smooth="true"  duration={500}  exact activeClassName="click"> <h4>Services</h4> </NavLink>
                     <NavLink onClick={logOut} to="/#"  smooth="true" duration={500}> <h4>LogOut</h4> </NavLink>
                </ul>
                <div onClick={handleNavlinkToggle} className='hamburger-toggle'>
                    <i className='fas fa-bars fa-lg'></i>
                </div>
             
            </nav>
        </div>
     );
}
 
export default AdminNavbar;