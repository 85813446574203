import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as moment from "moment";
import { TextField,Button } from '@material-ui/core';
import DatePicker from "react-datepicker";

export default function ViewCustomer(props) {
  return (
    <>
      <Section>
      <div className='view__acc_container'>
      <div className="view__filterbox__container">
 
 
  <div className="view__filtersearch__container">
              <TextField className='txtfld' id="outlined-basic"   variant="outlined"  fullWidth={false}
                onChange={(e) => {props.onHandleSearch(e.target.value)}}
                value={props.search}
                placeholder="Search"
                style={{backgroundColor:'white'}}
              />
              </div>
</div>
        <h1>Customers List</h1>
          <table className="table table-bordered table-responsive-sm">
              <thead style={{backgroundColor:'#7fbf7f', color:'#ffffff'}}>
                  <tr>
                      <th>S.No</th>
                      <th>Name</th>     
                      <th>DOB</th>      
                      <th>Gender</th>                                       
                      <th>Phone </th>  
                      <th>Place</th>   
                      <th>Privilege Id</th>   
                      <th></th>
                      <th></th>
                  </tr>
              </thead>
              <tbody>
        {props.customer.length > 0 ? 
        props.customer.map((cus, index) => {
          return (        
              <tr key= {index} >
                  <td> {((props.cp-1)*5)+(index+1)} </td>
                  {/* <td> {moment(pur.entry_date).format("DD-MM-YYYY")} </td> */}
                  {/* <td> {moment(pur.pur_date).format("DD-MM-YYYY")} </td> */}
                  <td> {cus.cus_name} {}</td>
                  {/* <td> {moment(cus.dob).format("DD-MM-YYYY")} </td> */}
                  <td>  {moment(cus.dob).format("DD-MM-YYYY") !== 'Invalid date' ? moment(cus.dob).format("DD-MM-YYYY") : ''}</td>
                  <td> {cus.gender} </td>
                  <td> {cus.phone} </td>     
                  <td> {cus.place} </td>       
                  <td> {cus.privilege_id} </td>                 
                 
                  <td style={{cursor:'pointer'}}>
                      <i className="fa fa-edit"   onClick={()=>{props.onHandleUpdate(cus)}}></i>
                  </td> 
                  <td style={{cursor:'pointer'}}>
                    <i className="fa fa-trash" onClick={()=>{props.onHandleDelete(cus)}}></i>
                  </td>                     
                 
              </tr>
          );
          })
        :<tr><td>No Data</td></tr>
        }
                    
              </tbody>

          </table>

      </div>    
      </Section>
    </>
  )
}


const Section = styled.section`
.view__acc_container{
  margin:50px 50px;
}

.view__filterbox__container{
      margin:20px auto;
      display: flex;
      /* justify-content: space-between; */
      align-items: center;
      gap: 1rem;   
      padding:1rem;  
      background-color: #bfdfbf;
}
.view__filterdate__container{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;        
      flex:0.7;
}
.view__filtersearch__container{  
  flex:0.3;
  text-align:center;
}

@media screen and (min-width: 280px) and (max-width: 720px) {
    
    .view__filterbox__container{
      display: flex;
      flex-direction: column;
      }
    .view__filterdate__container{
      display: flex;
      flex-direction: column;
    }
  }

`;