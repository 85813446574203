import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';


import Login from './pages/login/Login';
// import Accounts from './pages/accounts/Accounts';
import Sales from './pages/sales/Sales';
import Products from './pages/purchase/Purchase';
import Customers from './pages/customers/Customers';
import Services from './pages/services/Services';
import BillService from './pages/billservice/BillService';

import { Redirect } from "react-router-dom";
import {BrowserRouter, Route} from 'react-router-dom';


export default function Router() {
  return (
    <div>  
          <BrowserRouter>  
            <Route exact path="/"  component={Login} /> 
            <Route exact path="/login"   component={Login} />
            {/* <Route exact path="/accounts"   component={Accounts} /> */}
            <Route exact path="/sales"   component={Sales} />
            <Route exact path="/products"   component={Products} />
            <Route exact path="/customers"   component={Customers} />

            <Route exact path="/services"   component={Services} />
            <Route exact path="/billservice"   component={BillService} />
            {/* <Route render={() => <Redirect to={{ pathname: "/" }} />} /> */}
            
          </BrowserRouter>    
    </div>
  )
}
