import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextField,Button } from '@material-ui/core';
import * as moment from "moment";
import AdminNavbar from '../../components/AdminNavbar/AdminNavbar'
import {useDispatch, useSelector} from 'react-redux';
import jwt_decode from "jwt-decode";
import Select from 'react-select';
import ViewService from "./ViewService";
import {addService,deleteService,updateService, getAllService,getAllServiceLength, allServiceClear, searchService,filterService} from '../../redux/actions/serviceAction';
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import OnLineErrors from '../../components/OnLineErrors';
import Success from "../../components/Success";
import './pagestyle.css'

export default function Services() {

  const [is_edit, setis_edit] = useState(false);
  const [id, setid] = useState("");
  const [service_name, setservice_name] = useState("");   
  const [category, setcategory] = useState("");
  const [category_val, setcategory_val] = useState("");
  const [regular_rate, setregular_rate] = useState("");
  const [member_rate, setmember_rate] = useState("");

  const [errors, seterrors] = useState([]);
  const [search, setsearch] = useState('');
  const [tot, setTot] = useState(0);

  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");


  const options = [
    { value: 'General Services', label: 'General Services' },
    { value: 'Clean Up', label: 'Clean Up' }, 
    { value: 'Facial', label: 'Facial' },           
    { value: 'Oily Skin Facial', label: 'Oily Skin Facial' },      
    { value: 'Dry Skin Facial', label: 'Dry Skin Facial' },      
    { value: 'Gold Facial', label: 'Gold Facial' },      
    { value: 'Whitening Facial', label: 'Whitening Facial' },      
    { value: 'Acne Facial', label: 'Acne Facial' },      
    { value: 'Pedicure', label: 'Pedicure' },      
    { value: 'Manicure', label: 'Manicure' },      
    { value: 'Hair Cuts for Men', label: 'Hair Cuts for Men' },  
    { value: 'Hair Cuts for Women', label: 'Hair Cuts for Women' },      
    { value: 'Body SPA', label: 'Body SPA' },
    { value: 'Waxing', label: 'Waxing' },
    { value: 'Under Arms', label: 'Under Arms' },
    { value: 'Coloring for Women', label: 'Coloring for Women' },
    { value: 'Coloring for Men', label: 'Coloring for Men' },
    { value: 'Hair Treatment', label: 'Hair Treatment' },
    { value: 'Kids Hair Cut', label: 'Kids Hair Cut' },
    { value: 'Kids Makeup', label: 'Kids Makeup' },
    { value: 'Makeup Mixture', label: 'Makeup Mixture' },
    { value: 'GROOM MAKEUP', label: 'GROOM MAKEUP' },
    { value: 'BRIDAL MAKEUP', label: 'BRIDAL MAKEUP' },
    { value: 'Skin Treatment', label: 'Skin Treatment' },
    { value: 'Biolight Treatment', label: 'Biolight Treatment' },
    { value: '2000 PACKAGE', label: '2000 PACKAGE' },
    { value: '2500 PACKAGE', label: '2500 PACKAGE' },
    { value: '3000 PACKAGE', label: '3000 PACKAGE' },
    { value: 'Offer for Men', label: 'Offer for Men' },
    { value: 'Offer for Women', label: 'Offer for Women' },
  ];
  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: errors.category ==='*' ? "red" : "gray",
    })  
  };
  

  const userState = useSelector(state=>state.loginUserReducer)
  const {currentUser} = userState;

  const all_service_state = useSelector(state=>state.getAllServiceReducer)
  const {service} =  all_service_state;

  //console.log('------SERVICE-------', service)

useEffect(() => {           
  if(currentUser.length>0){             
      var decoded = jwt_decode(userState.currentUser);           
      if(decoded.role !== 'admin')
      {  window.location.href="/billservice"  }
      } else { window.location.href="/login" }
      setData(service)
       dispatch(allServiceClear())
       dispatch(getAllServiceLength()).then((response)=>{
        setTot(response.response.data.results[0].tot)
       })
       dispatch(getAllService(1,itemsPerPage))
},[])

useEffect(() => {                    
  setData(service) 
},[service])

useEffect(() => { 
if(search.length < 1){dispatch(getAllService(currentPage,itemsPerPage))}
},[search])

const dispatch = useDispatch()

const handleUpdate = (ser) => {   
 console.log('------SER UPDATE-------',ser.category) 
  setis_edit(true)   
  setid(ser.id)
  setservice_name(ser.service_name)
  setcategory(ser.category)
  setcategory_val(ser.category)
  setregular_rate(ser.regular_rate)
  setmember_rate(ser.member_rate)
  window.scrollTo({
    top: 100,
    behavior: 'smooth',
  })
}

const handleClear = () => {     
  // console.log('------clear-------')
  setis_edit(false)   
  setservice_name('')  
  setcategory('')
  setregular_rate('')
  setmember_rate('')
}

const handleDelete = (ser) => {
  console.log('####',ser.id)
  var option = window.confirm(`Are You Sure want to delete Bill Num ${ser.service_name}`);
  if (option) {
          dispatch(deleteService(ser.id))
          .then(res=>{  
           dispatch(allServiceClear())
           dispatch(getAllService(1,itemsPerPage))           
          })
 }
};

const handleSearch = (val) => {
  setfrom_date('');
  setto_date('');
  setsearch(val)
  dispatch(searchService(val)).then((res)=>{
    setData(res.response.data.results)
  }).catch(err=>{
    setsearch('')
    dispatch(getAllService(1,itemsPerPage))
  })
}

const handleSearchdate = ()=>{   
  if (from_date !== '' && to_date !=='') {
    setsearch('');
    const data = {from_date, to_date}   
    dispatch(filterService(data)).then((res)=>{
      console.log('------ RP ------',res.response.data.results)
      setData(res.response.data.results)
    }).catch(err=>{      
      dispatch(getAllService(1,itemsPerPage))
    })
  }           
  else {
    alert('Select date')
  }  
}  



     const Submit = () =>{
        const user_data = {id, service_name, category:category, regular_rate, member_rate }
        console.log('000000',user_data)
        const errors = validate(user_data);
        seterrors(errors)
        console.log('@@@@@',errors)
        if (Object.keys(errors).length === 0) {
          if(!is_edit){
            dispatch(addService(user_data))
            .then(res=>{
                dispatch(getAllService(1,itemsPerPage))
                alert(res.response.data.msg)
                handleClear();
            })
          }else{
            dispatch(updateService(user_data))
            .then((res)=>{                       
                setis_edit(false)
                handleClear()
                dispatch(getAllService(currentPage,itemsPerPage))
                if(res.response.data.success){
                  alert('Updated')
                }
            })
          }        
        }
        else{
        // console.log('@#@#@#@#',errors)
        }
     }   

     // ------------- Pagination start---------- //
     const [currentPage, setcurrentPage] = useState(1);
     const [itemsPerPage, setitemsPerPage] = useState(5);

     const [pageNumberLimit, setpageNumberLimit] = useState(5);
     const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
     const [minPageNumberLimit, setminPageNumberLimit] = useState(0);


     const [data, setData] = useState([]);

     const handleClick = (event) => {
       setcurrentPage(Number(event.target.id));
       dispatch(getAllService((Number(event.target.id)),itemsPerPage))
     };

     const pages = [];
     for (let i = 1; i <= Math.ceil(tot / itemsPerPage); i++) {
      pages.push(i);
     }


   const indexOfLastItem = currentPage * itemsPerPage;
   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
   //const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

   useEffect(() => {
     dispatch(getAllService(currentPage,itemsPerPage))
   },[currentPage, itemsPerPage])

   const handleNextbtn = () => {
     setcurrentPage(currentPage + 1); 
     // dispatch(getAllPurchase(currentPage)) 
     if (currentPage + 1 > maxPageNumberLimit) {
       setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
       setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
     }
   };

   const handlePrevbtn = () => {
     setcurrentPage(currentPage - 1);  
     if ((currentPage - 1) % pageNumberLimit == 0) {
       setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
       setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
     }
   };

   let pageIncrementBtn = null;
   if (pages.length > maxPageNumberLimit) {
     pageIncrementBtn = <li onClick={handleNextbtn}> &hellip;+ </li>;
   }
 
   let pageDecrementBtn = null;
   if (minPageNumberLimit >= 1) {
     pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip;- </li>;
   }

   const renderPageNumbers = pages.map((number) => {
     if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
       return (
         <li
           key={number}
           id={number}
           onClick={handleClick}
           className={currentPage == number ? "active" : null}
         >
           {number}
         </li>
       );
     } else {
       return null;
     }
   });

   const handleLoadMore = () => {
     setitemsPerPage(itemsPerPage + 5);
     setmaxPageNumberLimit(5);
     setminPageNumberLimit(0);
     setcurrentPage(1)
   }; 

    // ------------- Pagination end---------- //

     const validate = (data) => {
      const errors={};  
       
        if (!data.service_name) {
          errors.service_name = "*"
        }           
        if (!data.category) {
          errors.category = "*"
        }  
        if (!data.regular_rate) {
          errors.regular_rate = "*"
        }     
        if (!data.member_rate) {
          errors.member_rate = "*"
        }          
        
           
      return errors;
    };

  return (
    <>
     <AdminNavbar />
     <br/>
    {/* <p style={{fontSize:'2.5rem'}} className="text-center">SERVICES</p> */}
     <Section>
    <>
         
           {/* {loading && (<Loading/>)}          
           {error && (<Error success="Something Went Wrong" />)}
           {success &&(<Success success="Inserted Successfully" />)} */}
         

    <ul className="wrapper">
 
      <li className="form-row">
       <label htmlFor="name">Service Name</label>
       <TextField className='txtfld' id="outlined-basic" error={ errors.service_name ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {setservice_name(e.target.value)}}
        value={service_name}
        />
     </li>

   
     <li className="form-row">
       <label htmlFor="name">Category</label>
       <div style={{width:'65%'}}>
           <Select
             value={category_val}
             onChange={(e)=>{setcategory_val(e);setcategory(e.value)}}                                        
             options={options}
             placeholder={is_edit ? category : 'Select...'}
             styles={customStyles}
            />              
       </div>      
     </li>
           
     <li className="form-row">
       <label htmlFor="name">Regular Rate</label>
       <TextField className='txtfld' id="outlined-basic" error={ errors.regular_rate ? true : false} type="number"   variant="outlined"  fullWidth={false}
        onChange={(e) => {setregular_rate(e.target.value)}}
        value={regular_rate}
        />
     </li>

         
     <li className="form-row">
       <label htmlFor="name">Member Rate</label>
       <TextField className='txtfld' id="outlined-basic" error={ errors.member_rate ? true : false} type="number"   variant="outlined"  fullWidth={false}
        onChange={(e) => {setmember_rate(e.target.value)}}
        value={member_rate}
        />
     </li>

     
     <br/>
     <li className="form-row">
     <Button variant="contained" color="primary" onClick={Submit}> {is_edit === false ? 'Submit': 'Update'} </Button>         
     </li>
   </ul>

 </>
     </Section>
     <ViewService 
        service={data} 
        search={search}
        from_date={from_date}
        to_date={to_date}
        cp={currentPage} 
        onHandleUpdate={handleUpdate}
        onHandleDelete={handleDelete}
        onHandleSearch={handleSearch}
        onHandleFromDate={(date)=>{ setfrom_date(moment(date).format("DD-MM-YYYY"))}}
        onHandleToDate={(date)=>{ setto_date(moment(date).format("DD-MM-YYYY"))}}
        onHandleSearchDate={handleSearchdate}
     />
         {
  search.length>0 || from_date.length > 0 ? 
  null
  :
  <>
     <div className="pageNumbers">    
         <button
            onClick={handlePrevbtn}
            disabled={currentPage == pages[0] ? true : false}
          >   &lt;&lt;
          </button>
          {pageDecrementBtn} 
          {renderPageNumbers}       
          {pageIncrementBtn}
        <button
            onClick={handleNextbtn}
            disabled={currentPage == pages[pages.length - 1] ? true : false}
          >   &gt;&gt;
        </button>  
    </div> 
    <div style={{display:'flex',justifyContent:'center', margin:'20px 50px'}}>
     <button onClick={handleLoadMore} className="loadmore">
        Load More
      </button>
    </div>      
    </>
}   
    </>
   
  )
}

const Section = styled.section`

margin:50px auto;
max-width:650px !important;
 .wrapper {
    background-color: #ffffff;
    border:1px solid #3B2774;
    list-style-type: none;
    padding: 10px 50px;
    border-radius: 10px;   
  }

   
  .form-row {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;

  }
  .form-row > label {
    padding: .5em 1em .5em 0;
    flex: 1;
    color:#3B2774;
  }  

 
  .form-row .txtfld {
    flex: 2;
  }


  @media screen and (min-width: 280px) and (max-width: 720px) {
    .form-row{
      display: flex;
      flex-direction: column;
      }
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    .form-row{
      display: flex;
      flex-direction: column;
      }
  }

  
`;
