import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as moment from "moment";
import { TextField,Button } from '@material-ui/core';
import DatePicker from "react-datepicker";

export default function ViewPurchase(props) {
  return (
    <>
      <Section>
      <div className='view__acc_container'>
      <div className="view__filterbox__container">
  <div className="view__filterdate__container">
              <div style={{marginTop:'-0.5rem'}}>               
                <DatePicker           
                    value={props.from_date !== 'Invalid date' ? props.from_date : ''}
                    onChange={(date)=>{props.onHandleFromDate(date)}}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="From (Bill Date)"
                    monthsShown={1}
                    showYearDropdown
                  />                 
              </div>                          
                
              <div style={{marginTop:'-0.5rem'}}>      
              <DatePicker           
                    value={props.to_date !== 'Invalid date' ? props.to_date : ''}
                    onChange={(date)=>{props.onHandleToDate(date)}}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="To (Bill Date)"
                    monthsShown={1}
                    showYearDropdown
                  />                    
              </div>

              <div>
              <Button variant="contained" color="secondary"
              onClick={()=>{props.onHandleSearchDate()}}
              ><i className="fa fa-search"></i> </Button>         
             </div>
  </div >
 
  <div className="view__filtersearch__container">
              <TextField className='txtfld' id="outlined-basic"   variant="outlined"  fullWidth={false}
                onChange={(e) => {props.onHandleSearch(e.target.value)}}
                value={props.search}
                placeholder="Search"
                style={{backgroundColor:'white'}}
              />
              </div>
</div>
        <h1>PURCHASE</h1>
          <table className="table table-bordered table-responsive-sm">
              <thead style={{backgroundColor:'rgb(243, 128, 148)', color:'#ffffff'}}>
                  <tr>
                      <th>S.No</th>
                      <th>PurchaseDate</th>                                      
                      <th>Product Name</th>  
                      <th>Description</th>   
                      <th>Category</th>                                 
                      <th>Quantity</th>  
                      <th>Amount</th>  
                      <th></th>
                      <th></th> 
                  </tr>
              </thead>
              <tbody>
        {props.purchase.length > 0 ? 
        props.purchase.map((pur, index) => {
          return (        
              <tr key= {index} >
                  <td> {((props.cp-1)*5)+(index+1)} </td>
                  {/* <td> {moment(pur.entry_date).format("DD-MM-YYYY")} </td> */}
                  <td> {moment(pur.pur_date).format("DD-MM-YYYY")} </td>
                  <td> {pur.pro_name} </td>
                  <td> {pur.description} </td>     
                  <td> {pur.category} </td>                      
                  <td> {pur.quantity} </td>               
                  <td> {pur.bill_amount} </td>    
                  <td style={{cursor:'pointer'}}>
                      <i className="fa fa-edit"   onClick={()=>{props.onHandleUpdate(pur)}}></i>
                  </td>                      
                  <td style={{cursor:'pointer'}}>
                    <i className="fa fa-trash" onClick={()=>{props.onHandleDelete(pur)}}></i>
                  </td>
              </tr>
          );
          })
        :<tr><td>No Data</td></tr>
        }
                    
              </tbody>

          </table>

      </div>    
      </Section>
    </>
  )
}


const Section = styled.section`
.view__acc_container{
  margin:50px 50px;
}

.view__filterbox__container{
      margin:20px auto;
      display: flex;
      /* justify-content: space-between; */
      align-items: center;
      gap: 1rem;   
      padding:1rem;  
      background-color: #FBc1c1;
}
.view__filterdate__container{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;        
      flex:0.7;
}
.view__filtersearch__container{  
  flex:0.3;
  text-align:center;
}

@media screen and (min-width: 280px) and (max-width: 720px) {
    
    .view__filterbox__container{
      display: flex;
      flex-direction: column;
      }
    .view__filterdate__container{
      display: flex;
      flex-direction: column;
    }
  }

`;