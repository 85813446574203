

export const getAddAccountReducer=(state={}, action)=>{

    switch(action.type)
    {
        case 'ADD_ACCOUNT_REQUEST': return{
            loading:true,
            ...state
        }
        case 'ADD_ACCOUNT_SUCCESS': return{
            loading:false,
            success:true
        }
        case 'ADD_ACCOUNT_FAILED': return{
            loading:false,
            error:action.payload
        }
       
        case 'ALL_ACCOUNTS_CLEAR': return{
            loading:false,
            success:false,
        }
        
        default : return state
    }
}

export const getAllAccountsReducer=(state={accounts:[]}, action)=>{

    switch(action.type)
    {
        case 'GET_ACCOUNTS_REQUEST': return{
            loading:true,
            ...state
        }
        case 'GET_ACCOUNTS_SUCCESS': return{
            loading:false,
           accounts:action.payload
        }
        case 'GET_ACCOUNTS_FAILED': return{
            loading:false,
            error:action.payload
        }
        default : return state
    }
}