import React, { useState, useEffect } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

export default function RadioComponent(props)  {

  const [gender, setgender] = useState("");
 
  useEffect(() => { 
    setgender(props.val) 
    },[props.val])
    
   
   const onChange = (e)=>{
    setgender( e.target.value)
   }

   useEffect(() => {     
    props.onGender(gender);
    },[gender])


    return ( 
      <FormControl component="fieldset">
        {/* <FormLabel component="legend">Gender</FormLabel> */}
        <RadioGroup row aria-label="position" name="position">
          <FormControlLabel
            checked={gender === 'male' ? true : false }
            value='male' 
            control={<Radio color="primary" />}
            label="Male"
            onChange={(e) => {
              onChange(e);
            }}
          />

          <FormControlLabel
            checked={gender === 'female' ? true : false }
            value='female' 
            control={<Radio color="primary" />}
            label="Female"
            onChange={(e) => {
              onChange(e);
            }}
          />
        </RadioGroup>
      </FormControl>
    );

}
