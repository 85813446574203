

export const getAddPurchaseReducer=(state={}, action)=>{

    switch(action.type)
    {
        case 'ADD_PURCHASE_REQUEST': return{
            loading:true,
            ...state
        }
        case 'ADD_PURCHASE_SUCCESS': return{
            loading:false,
            success:true
        }
        case 'ADD_PURCHASE_FAILED': return{
            loading:false,
            error:action.payload
        }
        
        case 'ALL_PURCHASE_CLEAR': return{
            loading:false,
            success:false
        }
        
        default : return state
    }
}

export const getAllPurchaseReducer=(state={purchase:[]}, action)=>{

    switch(action.type)
    {
        case 'GET_PURCHASE_REQUEST': return{
            loading:true,
            ...state
        }
        case 'GET_PURCHASE_SUCCESS': return{
            loading:false,
            purchase:action.payload
        }
        case 'GET_PURCHASE_FAILED': return{
            loading:false,
            error:action.payload
        }
        default : return state
    }
}