import axios from 'axios';
import { apiUrl } from "../../config";


export const addCustomer = (acc)=>async dispatch=>{
console.log(' CCCCCUUUUSSSS------>',acc)
       dispatch({type:'ADD_CUSTOMER_REQUEST'})
       try {
           const response = await axios.post(`${apiUrl}/api/customer/create`,acc)
           console.log('+++ add',response)
           dispatch({type:'ADD_CUSTOMER_SUCCESS'})
           return{
               response
           }
          
       } catch (error) {
           dispatch({type:'ADD_CUSTOMER_FAILED', payload:error}) 
       }
   }



export const allCustomersClear = (acc)=>async dispatch=>{
    try {
      
        dispatch({type:'ALL_CUSTOMER_CLEAR'})
    } catch (error) {
        dispatch({type:'ALL_CUSTOMER_FAILED', payload:error}) 
    }
}   

export const getAllCustomerLength=()=>async dispatch=>{
    try{
        const response =await axios.get(`${apiUrl}/api/customer/getallcustomer_length`)    
        console.log('%%%111%%%',response.data.results[0].tot);
       return{  response  }  
    } catch(error){   
    }
    }

export const getAllCustomer=(offset,row_count)=>async dispatch=>{
    dispatch({type:'GET_CUSTOMER_REQUEST'})

try{
    const response =await axios.get(`${apiUrl}/api/customer/getallcustomer/${offset}/${row_count}`)    
   console.log('%%%%%%',response);
    dispatch({type:'GET_CUSTOMER_SUCCESS',payload:response.data.results})
} catch(error){
    dispatch({type:'GET_CUSTOMER_FAILED', payload:error})
}
}


export const updateCustomer = (pur)=>async dispatch=>{
   
    try {
        const response = await axios.post(`${apiUrl}/api/customer/update`,pur)    
        return{  response  }
       // window.location.reload()
    } catch (error) {
        alert('Something Went Wrong')
        console.log(error)
    }
}

export const deleteCustomer = (accid)=>async dispatch=>{
    try {
        const response = await axios.post(`${apiUrl}/api/customer/delete`,{accid})    
        return{  response  }
       // window.location.reload()
    } catch (error) {
        alert('Something Went Wrong')
        console.log(error)
    }
}


export const searchCustomer = (val)=>async dispatch=>{
    const data={id:val}
    try {
        const response = await axios.post(`${apiUrl}/api/customer/search`,data)          
        return{  response  }
       // window.location.reload()
    } catch (error) {
        return{  error  }
        //alert('Something Went Wrong')
        console.log(error)
    }
}
