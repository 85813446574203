import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as moment from "moment";
import { TextField,Button } from '@material-ui/core';
import DatePicker from "react-datepicker";
import Switch from "react-switch";

export default function ViewBillService(props) {
  return (
    <>
      <Section>
      <div className='view__acc_container'>
      <div className="view__filterbox__container">
  <div className="view__filterdate__container">
              <div style={{marginTop:'-0.5rem'}}>               
                <DatePicker           
                    value={props.from_date !== 'Invalid date' ? props.from_date : ''}                  
                    onChange={(date)=>{props.onHandleFromDate(date)}}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="From (Bill Date)"
                    monthsShown={1}
                    showYearDropdown
                  />                 
              </div>                          
                
              <div style={{marginTop:'-0.5rem'}}>      
              <DatePicker           
                    value={props.to_date !== 'Invalid date' ? props.to_date : ''}
                    onChange={(date)=>{props.onHandleToDate(date)}}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="To (Bill Date)"
                    monthsShown={1}
                    showYearDropdown
                  />                    
              </div>

              <div>
              <Button variant="contained" color="secondary"
              onClick={()=>{props.onHandleSearchDate()}}
              ><i className="fa fa-search"></i> </Button>         
             </div>

             <div>
             <Button variant="contained" color="primary" onClick={()=>{props.onBillFetch()}}> Bill </Button>  
             </div>
  </div >
 
  <div className="view__filtersearch__container">
              <TextField className='txtfld' id="outlined-basic"   variant="outlined"  fullWidth={false}
                onChange={(e) => {props.onHandleSearch(e.target.value)}}
                value={props.search}
                placeholder="Search"
                style={{backgroundColor:'white'}}
              />
              </div>
</div>
        <h1>Bill(Services)</h1>
        <div className="table-responsive" style={{marginTop:'-0.1rem',overflow:'auto',height:'500px'}}>       
          <table className="table table-bordered table-responsive-sm">
              <thead style={{backgroundColor:'#ffd500', color:'#000000'}}>
                  <tr>
                      <th>S.No</th>
                      <th>Bill Date</th>
                      <th>Customer Name</th>                                      
                      <th>Phone</th>  
                      <th>Place</th>  
                      <th>Service Name</th>                  
                      <th>Amount</th>    
                      <th>PayMode</th>    
                      <th></th>                  
                      <th></th> 
                  </tr>
              </thead>
              <tbody>
        {props.billservice.length > 0 ? 
        props.billservice.map((ser, index) => {
          return (        
              <tr key= {index} >
                  <td> {(index+1)} </td>
                  <td> {moment(ser.entry_date).format("DD-MM-YYYY")} </td>                 
                  <td> {ser.cus_name} </td>
                  <td> {ser.phone} </td>
                  <td> {ser.place} </td>
                  <td> {ser.service_name} </td>                               
                  <td> {ser.bill_amount} </td>    
                  <td> {ser.pay_mode} </td>  
                  <td>
                     <Switch                         
                      onChange={(val)=>{props.onHandleSwitchChange(ser,val)}}                      
                      checked={ser.bill_checked === 1 ? true : false}   
                     /> 
                 </td>
                                                   
                  <td style={{cursor:'pointer'}}>
                    <i className="fa fa-trash" onClick={()=>{props.onHandleDelete(ser)}}></i>
                  </td>
              </tr>
          );
          })
        :<tr><td>No Data</td></tr>
        }
                    
              </tbody>

          </table>
       </div>
      </div>    
      </Section>
    </>
  )
}


const Section = styled.section`
.view__acc_container{
  margin:50px 50px;
}

.view__filterbox__container{
      margin:20px auto;
      display: flex;
      /* justify-content: space-between; */
      align-items: center;
      gap: 1rem;   
      padding:1rem;  
      background-color: #fffac4;
}
.view__filterdate__container{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;        
      flex:0.7;
}
.view__filtersearch__container{  
  flex:0.3;
  text-align:center;
}

@media screen and (min-width: 280px) and (max-width: 720px) {
    
    .view__filterbox__container{
      display: flex;
      flex-direction: column;
      }
    .view__filterdate__container{
      display: flex;
      flex-direction: column;
    }
  }

`;