import axios from 'axios';
import { apiUrl } from "../../config";


export const addPurchase = (acc)=>async dispatch=>{
console.log('ACTION------>',acc)
       dispatch({type:'ADD_PURCHASE_REQUEST'})
       try {
           const response = await axios.post(`${apiUrl}/api/purchase/create`,acc)
           console.log('+++ add',response)
           dispatch({type:'ADD_PURCHASE_SUCCESS'})
           return{
               response
           }
          
       } catch (error) {
           dispatch({type:'ADD_PURCHASE_FAILED', payload:error}) 
       }
   }



export const allPurchaseClear = (acc)=>async dispatch=>{
    try {
      
        dispatch({type:'ALL_PURCHASE_CLEAR'})
    } catch (error) {
        dispatch({type:'ALL_PURCHASE_FAILED', payload:error}) 
    }
}   


export const getAllPurchaseLength=()=>async dispatch=>{
try{
    const response =await axios.get(`${apiUrl}/api/purchase/getallpurchase_length`)    
    //console.log('%%%111%%%',response.data.results[0].tot);
   return{  response  }  
} catch(error){   
}
}

export const getAllPurchase=(offset,row_count)=>async dispatch=>{
    dispatch({type:'GET_PURCHASE_REQUEST'})

try{
    const response =await axios.get(`${apiUrl}/api/purchase/getallpurchase/${offset}/${row_count}`)    
   // console.log('%%%%%%',response);
    dispatch({type:'GET_PURCHASE_SUCCESS',payload:response.data.results})
} catch(error){
    dispatch({type:'GET_PURCHASE_FAILED', payload:error})
}
}

export const updatePurchase = (pur)=>async dispatch=>{
   
    try {
        const response = await axios.post(`${apiUrl}/api/purchase/update`,pur)    
        return{  response  }
       // window.location.reload()
    } catch (error) {
        alert('Something Went Wrong')
        console.log(error)
    }
}

export const updatePurchaseQuantity = (quan)=>async dispatch=>{
    console.log('++++++======+++++++',quan)
   const data ={id:quan.id, quantity:quan.quantity, maxquantity:quan.maxquantity} 
    try {
        const response = await axios.post(`${apiUrl}/api/purchase/updatepurchasequantity`,data)    
        return{  response  }
       // window.location.reload()
    } catch (error) {
        alert('Something Went Wrong')
        console.log(error)
    }
}

export const deletePurchase = (accid)=>async dispatch=>{
    try {
        const response = await axios.post(`${apiUrl}/api/purchase/delete`,{accid})    
        return{  response  }
       // window.location.reload()
    } catch (error) {
        alert('Something Went Wrong')
        console.log(error)
    }
}

export const deletePurchaseQuantity = (quan)=>async dispatch=>{
    console.log('++++++======+++++++',quan)
   const data ={id:quan.p_id, quantity:quan.quantity} 
    try {
        const response = await axios.post(`${apiUrl}/api/purchase/deletepurchasequantity`,data)    
        return{  response  }
       // window.location.reload()
    } catch (error) {
        alert('Something Went Wrong')
        console.log(error)
    }
}

export const searchPurchase = (val)=>async dispatch=>{
    const data={id:val}
    try {
        const response = await axios.post(`${apiUrl}/api/purchase/search`,data)          
        return{  response  }
       // window.location.reload()
    } catch (error) {
        return{  error  }
        //alert('Something Went Wrong')
        console.log(error)
    }
}

export const filterPurchase = (data)=>async dispatch=>{
    try {
        const response = await axios.post(`${apiUrl}/api/purchase/filterdate`,data)          
        return{  response  }        
       // window.location.reload()
    } catch (error) {
        return{  error  }
        //alert('Something Went Wrong')
        console.log(error)
    }
}
