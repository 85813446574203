import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextField,Button } from '@material-ui/core';
import * as moment from "moment";
import AdminNavbar from '../../components/AdminNavbar/AdminNavbar';
import {useDispatch, useSelector} from 'react-redux';
import jwt_decode from "jwt-decode";
import Select from 'react-select';

import ViewCustomers from "./ViewCustomers";
import ViewService from "./ViewService";
import ViewBillService from "./ViewBillService";
import { searchCustomer} from '../../redux/actions/customerAction';
import {addService,deleteService,updateService, getAllService,getAllServiceLength, allServiceClear, searchService,filterService, } from '../../redux/actions/serviceAction';
import {addBillService,deleteBillService, getAllBillService,getAllBillServicebycus,getAllBillServiceLength, allBillServiceClear, searchBillService,filterBillService, CheckedBillService} from '../../redux/actions/billServiceAction';

import Loading from "../../components/Loading";
import Error from "../../components/Error";
import OnLineErrors from '../../components/OnLineErrors';
import Success from "../../components/Success";
import './pagestyle.css';
import './printsales.css'
import { useReactToPrint } from "react-to-print";
import Modal from 'react-modal';
import logo from '../../assets/logo.png';

var datetime = new Date();
var tdy_date = (datetime.toISOString().slice(0,10)).split("-").reverse().join("-");  
var t_date = (datetime.toISOString().slice(0,10)).split("-").join("-");

export default function BillService() {


    const [is_edit, setis_edit] = useState(false);
    const [id, setid] = useState("");
    const [service_name, setservice_name] = useState("");  
    const [category, setcategory] = useState("");
    const [bill_amount, setbill_amount] = useState("");
    const [bill_checked, setbill_checked] = useState("");
    const [pay_mode, setpay_mode] = useState("");   
    const [errors, seterrors] = useState([]);
    const [search, setsearch] = useState('');
    const [tot, setTot] = useState(0);

    const [from_date, setfrom_date] = useState("");
    const [to_date, setto_date] = useState("");

    const userState = useSelector(state=>state.loginUserReducer)
    const {currentUser} = userState;

    const all_customer_state = useSelector(state=>state.getAllCustomerReducer)
    const {customer} =  all_customer_state;

    const all_service_state = useSelector(state=>state.getAllServiceReducer)
    const {service} =  all_service_state;

    const all_billservice_state = useSelector(state=>state.getAllBillServiceReducer)
    const {billservice} =  all_billservice_state;
   //console.log('-------@#@#@#@#@#@#@#@#@#@#-------',billservice);

    const options = [
        { value: 'General Services', label: 'General Services' },
        { value: 'Clean Up', label: 'Clean Up' }, 
        { value: 'Facial', label: 'Facial' },           
        { value: 'Only Skin Facial', label: 'Only Skin Facial' },      
        { value: 'Dry Skin Facial', label: 'Dry Skin Facial' },      
        { value: 'Gold Facial', label: 'Gold Facial' },      
        { value: 'Whitening Facial', label: 'Whitening Facial' },      
        { value: 'Acne Facial', label: 'Acne Facial' },      
        { value: 'Pedicure', label: 'Pedicure' },      
        { value: 'Manicure', label: 'Manicure' },      
        { value: 'Hair Cuts for Mens', label: 'Hair Cuts for Mens' },  
        { value: 'Hair Cuts for Womens', label: 'Hair Cuts for Womens' },      
        { value: 'Body SPA', label: 'Body SPA' },
        { value: 'Waxing', label: 'Waxing' },
        { value: 'Under Arms', label: 'Under Arms' },
        { value: 'Coloring for Womens', label: 'Coloring for Womens' },
        { value: 'Coloring for Mens', label: 'Coloring for Mens' },
        { value: 'Hair Treatments', label: 'Hair Treatments' },
      ];

      const optionspm = [
        { value: 'Cash', label: 'Cash'},
        { value: 'Card', label: 'Card' },
        { value: 'Upi', label: 'Upi' },      
      ];

      const customStyles = {      
        control: (base) => ({
          ...base,
          borderColor: errors.category ==='*' ? "red" : "gray",
        })  
      };

    useEffect(() => {           
      handleTodayDate();
        if(currentUser.length>0){             
            var decoded = jwt_decode(userState.currentUser);           
            if((decoded.role !== 'admin') && (decoded.role !== 'sarra'))
            {  window.location.href="/login"  }
            } else { window.location.href="/login" }
            setservice_data(service)
            setcus_data(customer)
            setData(billservice)

             dispatch(allBillServiceClear())
             dispatch(getAllBillServiceLength()).then((response)=>{
             setTot(response.response.data.results[0].tot)
             })
             dispatch(getAllBillService(1,itemsPerPage))

             dispatch(allServiceClear())
            //  dispatch(getAllBillServiceLength()).then((response)=>{
            //   setTot(response.response.data.results[0].tot)
            //  })
             dispatch(getAllService(1,10))
            
    },[])


    useEffect(() => {                    
      setData(billservice)          
    },[billservice])

    useEffect(() => {                    
      setservice_data(service)          
    },[service])

    useEffect(() => { 
      if(search.length < 1){dispatch(getAllService(currentPage,10))}
     },[search])


    const dispatch = useDispatch();

    const handleClear = () =>{
      setservice_name('');
      setcategory('');
      setbill_amount('')
    }

    const handleDelete = (bs) => {
        //console.log('####',bs.id)
        var option = window.confirm(`Are You Sure want to delete Bill Num ${bs.service_name}`);
        if (option) {
                dispatch(deleteBillService(bs.id))
                .then(res=>{  
                 dispatch(allBillServiceClear())
                 dispatch(getAllBillService(1,itemsPerPage,cus_name, phone))
                })
       }
      };

      const handleSwitchChange = (bs,new_status)=>{
        //console.log('+++++tar++++',bs.id,new_status) 

       const data = {from_date, to_date, cus_name}  
        dispatch(CheckedBillService(bs.id, new_status)).then((res)=>{
          dispatch(filterBillService(data)).then((res)=>{
            //console.log('------ RP ------',res.response.data.results)
            setData(res.response.data.results)
          }).catch(err=>{     
            //console.log('------ ERR ------',err) 
            //dispatch(getAllBillService(1,itemsPerPage, cus_name, phone))
          })
        })
        }


      const handleSearch = (val) => {
        //setfrom_date('');
        //setto_date('');
        setsearch(val)
        dispatch(searchBillService(val)).then((res)=>{
          setData(res.response.data.results)
        }).catch(err=>{
          setsearch('')
          dispatch(getAllBillService(1,itemsPerPage, cus_name, phone))
        })
      }

      const handleSearchdate = ()=>{   
        if (from_date !== '' && to_date !=='') {
          setsearch('');
          if(cus_name ==='*'){setphone('**')}
          const data = {from_date, to_date, cus_name}   
          dispatch(filterBillService(data)).then((res)=>{
            //console.log('------ RP ------',res.response.data.results)
            setData(res.response.data.results)
          }).catch(err=>{      
            dispatch(getAllBillService(1,itemsPerPage, cus_name, phone))
          })
        }           
        else {
          alert('Select date')
        }  
      }  
        
      const Bill = () =>{        
        //console.log('FFFFFFFFF',from_date)
        dispatch(getAllBillServicebycus(1,itemsPerPage, cus_name, phone, from_date))
        .then(res=>{
          //console.log('&&&&&&&&&',res)
      })
      .then(()=>{
        openModal()
        handlePrint()
        closeModal()
     })
      }


    const Submit = () =>{
        const user_data = {id, cus_name, phone, place, service_name, bill_amount,pay_mode }
        //console.log('000000',user_data)
        const errors = validate(user_data);
        seterrors(errors)
       // console.log('@@@@@',errors)
        if (Object.keys(errors).length === 0) {
          if(!is_edit){
            dispatch(addBillService(user_data))
            .then(res=>{
                dispatch(getAllBillServicebycus(1,itemsPerPage, cus_name, phone, from_date))
                handleClear()
            })
          }else{
            // dispatch(updateSales(user_data))
            // .then((res)=>{
              
            //     setis_edit(false)
            //     handleClear()
            //     dispatch(getAllSales(currentPage,itemsPerPage, cus_name, phone))
            //     if(res.response.data.success){
            //       alert('Updated')
            //     }
            // })
          }        
        }
        else{
        // console.log('@#@#@#@#',errors)
        }
     } 

    const handleTodayDate = () =>{
        let datetime = new Date();
        let tdy_date = datetime.toISOString().slice(0,10);
        
        setfrom_date(moment(tdy_date).format("DD-MM-YYYY"));
        setto_date(moment(tdy_date).format("DD-MM-YYYY"));
       }
      

  // -------------- CUSTOMER Start------------- //
  const [cus_id, setcus_id] = useState(0);
  const [cus_data, setcus_data] = useState([]);
  const [cus_search, setcus_search] = useState('');
  const [cus_name, setcus_name] = useState("*");   
  const [phone, setphone] = useState("*");
  const [place, setplace] = useState("");   
  const [privilege_id, setprivilege_id] = useState("");   

 // console.log('---CUS ID---',cus_id,'----privilege_id----',privilege_id)

  const handleCustomerEdit = (cus) => {   
    setcus_id(cus.id)   
    setcus_name(cus.cus_name)
    setphone(cus.phone)
    setplace(cus.place)   
    setprivilege_id(cus.privilege_id)   
    window.scrollTo({
      top: 100,
      behavior: 'smooth',
    })
  }

  const handleSearchCusData  = (val) => {              
    setcus_search(val)
    dispatch(searchCustomer(val)).then((res)=>{
      setcus_data(res.response.data.results)
    }).catch(err=>{
      setcus_search('')
     
    })
  }      
  // --------------  CUSTOMER End  -----------------//

      // --------------  Service Start ------------- //
      const [service_data, setservice_data] = useState([]);
      const [service_search, setservice_search] = useState('');

      const handleSearchService = (val) => {        
        setservice_search(val)
       dispatch(searchService(val)).then((res)=>{
         setservice_data(res.response.data.results)
       }).catch(err=>{
         setsearch('')
         dispatch(getAllBillService(1,itemsPerPage))
       })
     }


   const handleEditService = (ser) => {   
    //console.log('### PRIVILEGE ID ###',privilege_id)     
    //console.log('### SERVICE ###',ser)     
     setid(ser.id)  
     setservice_name(ser.service_name)
     setcategory(ser.category)
     setbill_checked(ser.bill_checked)

     if(privilege_id){
      setbill_amount(ser.member_rate)
     } else { setbill_amount(ser.regular_rate)}

    // setbill_amount(ser.regular_rate)
     window.scrollTo({
       top: 100,
       behavior: 'smooth',
     })
   }
    // --------------  Service End  -----------------//
  const validate = (data) => {
    const errors={};  
     
      if (!data.cus_name || data.cus_name === '*') {
        errors.cus_name = "*"
      }  
      if (!data.phone || data.phone === '*') {
        errors.phone = "*"
      }    
      if (!data.place) {
        errors.place = "*"
      }      
      if (!data.service_name) {
        errors.service_name = "*"
      }         
      if (!data.bill_amount) {
        errors.bill_amount = "*"
      }          
    
         
    return errors;
  };
  // ------------- Pagination start---------- //
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(5);

  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);


  const [data, setData] = useState([]);

  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
    dispatch(getAllBillService((Number(event.target.id)),itemsPerPage))
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(tot / itemsPerPage); i++) {
   pages.push(i);
  }


const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

useEffect(() => {
  dispatch(getAllBillService(currentPage,itemsPerPage))
},[currentPage, itemsPerPage])

const handleNextbtn = () => {
  setcurrentPage(currentPage + 1); 
  // dispatch(getAllPurchase(currentPage)) 
  if (currentPage + 1 > maxPageNumberLimit) {
    setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
    setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
  }
};

const handlePrevbtn = () => {
  setcurrentPage(currentPage - 1);  
  if ((currentPage - 1) % pageNumberLimit == 0) {
    setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
    setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
  }
};

let pageIncrementBtn = null;
if (pages.length > maxPageNumberLimit) {
  pageIncrementBtn = <li onClick={handleNextbtn}> &hellip;+ </li>;
}

let pageDecrementBtn = null;
if (minPageNumberLimit >= 1) {
  pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip;- </li>;
}

const renderPageNumbers = pages.map((number) => {
  if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
    return (
      <li
        key={number}
        id={number}
        onClick={handleClick}
        className={currentPage == number ? "active" : null}
      >
        {number}
      </li>
    );
  } else {
    return null;
  }
});

const handleLoadMore = () => {
  setitemsPerPage(itemsPerPage + 5);
  setmaxPageNumberLimit(5);
  setminPageNumberLimit(0);
  setcurrentPage(1)
}; 

// ------------- Pagination end---------- //

const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }
  
  var subtotal  = billservice.reduce((x,sal)=>x+(sal.bill_amount), 0)


  return (
     <>
    <AdminNavbar />
    <br/>
    {/* <p style={{fontSize:'2.5rem'}} className="text-center">BILL (SERVICES)</p> */}
    <div className="row" >
            <div className="col-md-4">
            <ViewCustomers 
                customer={cus_data} 
                search={cus_search}
                from_date={from_date}
                to_date={to_date}
                // cp={currentPage}   
                onHandleSearchCusData={handleSearchCusData}  
                onHandleEdit={handleCustomerEdit} 
            /> 
            </div>

            <div className="col-md-8">
      <Section>
    <>
         
           {/* {loading && (<Loading/>)}          
           {error && (<Error success="Something Went Wrong" />)}
           {success &&(<Success success="Inserted Successfully" />)} */}
         

    <ul className="wrapper">

    <li className="form-row">
       <label htmlFor="name"  style={{textAlign:'left'}}>Customer Name</label>
       <TextField className='txtfld' id="outlined-basic" error={ errors.cus_name ? true : false}   variant="outlined"  fullWidth={false}
        // onChange={(e) => {setcus_name(e.target.value)}}
        value={cus_name}
        />
     </li>

     <li className="form-row">
       <label htmlFor="name"  style={{textAlign:'left'}}>Mobile Number</label>
       <TextField className='txtfld' id="outlined-basic" error={ errors.phone ? true : false}   variant="outlined"  fullWidth={false}
        // onChange={(e) => {setphone(e.target.value)}}
        value={phone}
        />
     </li>

     <li className="form-row">
       <label htmlFor="name"  style={{textAlign:'left'}}>Place</label>
       <TextField className='txtfld' id="outlined-basic" error={ errors.place ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {setcus_name(e.target.value)}}
        value={place}
        />
     </li>
             
      <li className="form-row">
       <label htmlFor="name"  style={{textAlign:'left'}}>Service Name</label>
       <TextField className='txtfld' id="outlined-basic" error={ errors.service_name ? true : false}   variant="outlined"  fullWidth={false}
        // onChange={(e) => {setservice_name(e.target.value)}}
        value={service_name}
        />
     </li>

   
     <li className="form-row">
       <label htmlFor="name"  style={{textAlign:'left'}}>Category</label>
       <div style={{width:'65%'}}>
           <Select
             value={category}
            //  onChange={setcategory}                                        
             options={options}
             placeholder={ category }
             styles={customStyles}
            />              
       </div>      
     </li>

   
         
     <li className="form-row">
       <label htmlFor="name"  style={{textAlign:'left'}}>Amount</label>
       <TextField className='txtfld' id="outlined-basic" error={ errors.bill_amount ? true : false} type="number"   variant="outlined"  fullWidth={false}
        onChange={(e) => {setbill_amount(e.target.value)}}
        value={bill_amount}
        />
     </li>

        
     <li className="form-row">
       <label htmlFor="name"  style={{textAlign:'left'}}>Payment Mode</label>
       <div style={{width:'65%'}}>
           <Select
             value={pay_mode}
             onChange={(e)=>{setpay_mode(e.value)}}                 
             options={optionspm}
             placeholder={ pay_mode }
             styles={customStyles}
            />              
       </div>      
     </li>

     <br/>
     <li className="form-row">
     <Button variant="contained" color="primary" onClick={Submit}> {is_edit === false ? 'Submit': 'Update'} </Button>         
     </li>
  
   </ul>

 </>
     </Section>
      </div>

     
    </div>
    <ViewBillService 
        billservice={data} 
        search={search}
        from_date={from_date}
        to_date={to_date}
        cp={currentPage} 
        // onHandleUpdate={handleUpdate}
        onHandleDelete={handleDelete}
        onHandleSearch={handleSearch}
        onHandleFromDate={(date)=>{ setfrom_date(moment(date).format("DD-MM-YYYY"))}}
        onHandleToDate={(date)=>{ setto_date(moment(date).format("DD-MM-YYYY"))}}
        onHandleSearchDate={handleSearchdate}
        onBillFetch={Bill}
        onHandleSwitchChange={handleSwitchChange}
     />    

{
  cus_name.length>1 || search.length >0 || phone === '**'? 
  null
  :
  <>
     <div className="pageNumbers">    
         <button
            onClick={handlePrevbtn}
            disabled={currentPage == pages[0] ? true : false}
          >   &lt;&lt;
          </button>
          {pageDecrementBtn} 
          {renderPageNumbers}       
          {pageIncrementBtn}
        <button
            onClick={handleNextbtn}
            disabled={currentPage == pages[pages.length - 1] ? true : false}
          >   &gt;&gt;
        </button>  
    </div> 
    <div style={{display:'flex',justifyContent:'center', margin:'20px 50px'}}>
     <button onClick={handleLoadMore} className="loadmore">
        Load More
      </button>
    </div>      
    </>
}


    <ViewService
        service={service_data} 
        search={service_search}
        from_date={from_date}
        to_date={to_date}
        cp={currentPage} 
        onHandleEditService={handleEditService}      
        onHandleSearchService={handleSearchService}      
     />


{/*  */}
<div style={{textAlign:'center'}}>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false} 
      >
        
       
        <div className="row" style={{backgroundColor:'#ffecf2', marginTop:'-1rem',}} >
          <div className="col-print-6">           
            <h2 ref={(_subtitle) => (subtitle = _subtitle)} onClick={handlePrint} style={{textAlign:'left', cursor:'pointer'}}>Print</h2>
          </div>
          <div className="col-print-6">
            <button onClick={closeModal} style={{float:'right',backgroundColor:'#ffecf2',fontSize:'2.5rem'}}>X</button> 
          </div>
          </div>      
        <>
       
    <style>{getPageMargins()}</style>
             
      <div ref={componentRef} >
      <>
          <div className="row" style={{textAlign:'left', marginTop:'3rem'}}>
              <div className="col-print-1">
              </div>
              <div className="col-print-11">
                  <div className="row">
                  <div className="col-print-0"> </div>
                    <div className="col-print-9">
                       {/* <h2 style={{textAlign:'left'}}>Logo</h2>   */}
                       <img src={logo} style={{textAlign:'left', height:'5rem', width:'8rem'}} />
                    </div>
                    <div className="col-print-3">
                       <h2 style={{textAlign:'left', marginTop:'3rem'}}>INVOICE</h2>  
                    </div>
                </div>
              </div>
              <div className="col-print-0">
              </div>
          </div>
          </>
        
            <div className="row" style={{textAlign:'center', marginTop:'2rem'}}>
                <div className="col-print-1">  </div>
                <div className="col-print-9">
                  <p style={{textAlign:'left',fontSize:'130%'}}>C3 Family Salon & Portrait <br/> 150, 2nd floor, Kumaran Complex, <br/>near busstand, Karur <br/>+91 9994138217 </p>  
                </div>
                <div className="col-print-3">  </div>
            </div>
 

          <div className="row" style={{textAlign:'center', marginTop:'2rem'}}>
              <div className="col-print-1">
              </div>
              <div className="col-print-7">
                <h5 style={{textAlign:'left',fontSize:'130%'}}>Bill To</h5>  
                <p style={{textAlign:'left',fontSize:'130%'}}>{cus_name} <br/> {place} <br/> {phone} </p>  
               
              </div>
              <div className="col-print-4">
                {/* <h5 style={{textAlign:'left',fontSize:'130%'}}>Invoice# : INV-12</h5>   */}
                <p style={{textAlign:'left',fontSize:'130%'}}>Invoice Date: {tdy_date} </p> 
              </div>
          </div>

        <>
        <div className="row" style={{textAlign:'center', marginTop:'4rem'}}>
        <div className="col-print-1">
        </div>
        <div className="col-print-10">
        <table className="table  table-responsive">
                <thead style={{backgroundColor:'#fff', color:'#1a4567',fontSize:'150%'}}>
                    <tr>        
                        <th style={{width:'100%'}}>Service Name</th> 
                        <th style={{width:'100%'}}>Amount</th> 
                    </tr>
                </thead>
            <tbody>
            {billservice.length > 0 ? 
          billservice.map((bs, index) => {
            return (        
                <tr key= {index} style={{fontSize:'130%'}}>         
                    <td> {bs.service_name} </td>    
                    <td> {bs.bill_amount}</td>  
                </tr>
                
            );
            })
          :<tr><td>No Data</td></tr>
          }
            </tbody>  
        </table>
<hr/>

<div  style={{marginTop:'2rem'}}>
    <p style={{textAlign:'right', marginRight:'2rem',fontSize:'130%'}}>SubTotal :  {Math.round(subtotal)}</p>  
    <p style={{textAlign:'right', marginRight:'2rem',fontSize:'130%'}}>SGST :  {Math.round(subtotal * 0.09)}</p>  
    <p style={{textAlign:'right', marginRight:'2rem',fontSize:'130%'}}>CGST :  {Math.round(subtotal * 0.09)}</p>  
    <p style={{textAlign:'right', marginRight:'2rem',fontSize:'150%', fontWeight:'bold'}}>Total Amount:  <span>&#8377;</span>  {subtotal}</p>        
</div>

        </div> 
        <div className="col-print-1">
        </div>   
           
       </div>
       </>
    

       

          </div>      

        </>
      </Modal>
    </div>
{/*  */}
    </>
  )
}



const marginTop="20px"
const marginRight="5px"
const marginBottom="10px"
const marginLeft="5px"
const getPageMargins = () => {
  return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
};


const Section = styled.section`
margin:50px auto;
max-width:650px !important;
 .wrapper {
    background-color: #ffffff;
    border:1px solid #ACDF87;
    list-style-type: none;
    padding: 10px 50px;
    border-radius: 10px;   
  }

   
  .form-row {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;

  }
  .form-row > label {
    padding: .5em 1em .5em 0;
    flex: 1;
    color:#1E5631;
  }  

 
  .form-row .txtfld {
    flex: 2;
  }


  @media screen and (min-width: 280px) and (max-width: 720px) {
    .form-row{
      display: flex;
      flex-direction: column;
      }
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    .form-row{
      display: flex;
      flex-direction: column;
      }
  }  
`;


