import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextField,Button } from '@material-ui/core';
import * as moment from "moment";
import AdminNavbar from '../../components/AdminNavbar/AdminNavbar'
import {useDispatch, useSelector} from 'react-redux';
import jwt_decode from "jwt-decode";
import Select from 'react-select';
import ViewCustomers from './ViewCustomers';
import {addCustomer,deleteCustomer,updateCustomer, getAllCustomer, allCustomersClear, getAllCustomerLength, searchCustomer} from '../../redux/actions/customerAction';
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import OnLineErrors from '../../components/OnLineErrors';
import RadioComponent from "../../components/Radio";
import Success from "../../components/Success";
import './pagestyle.css'


export default function Customer() {     

    const [is_edit, setis_edit] = useState(false);
    const [id, setid] = useState("");   
    const [cus_name, setcus_name] = useState("");   
    const [phone, setphone] = useState("");
    const [place, setplace] = useState("");   
    const [gender, setgender] = useState(""); 
    const [dob, setdob] = useState("");  
    const [privilege_id, setprivilege_id] = useState("");   

    const [errors, seterrors] = useState([]);
    const [search, setsearch] = useState('');
    const [tot, setTot] = useState(0);

    const [from_date, setfrom_date] = useState("");
    const [to_date, setto_date] = useState("");

    

     const userState = useSelector(state=>state.loginUserReducer)
     const {currentUser} = userState;
    
     const add_customer_state = useSelector(state=>state.getAddCustomerReducer)    
     const {success, error, loading} = add_customer_state;

     const all_customer_state = useSelector(state=>state.getAllCustomerReducer)
     const {customer} =  all_customer_state;

 
    
     useEffect(() => {           
        if(currentUser.length>0){             
            var decoded = jwt_decode(userState.currentUser);           
            if((decoded.role !== 'admin') && (decoded.role !== 'sarra'))
            {  window.location.href="/login"  }
            } else { window.location.href="/login" }
            setData(customer)
             dispatch(allCustomersClear())
             dispatch(getAllCustomerLength()).then((response)=>{
              console.log('#$#$#$#$#$#$#$',response.response.data.results[0].tot)
              setTot(response.response.data.results[0].tot)
             })
             dispatch(getAllCustomer(1,itemsPerPage))
    },[])

    useEffect(() => {                    
          setData(customer) 
    },[customer])

    useEffect(() => { 
      if(search.length < 1){dispatch(getAllCustomer(currentPage,itemsPerPage))}
     },[search])
       
    const dispatch = useDispatch()


    const handleUpdate = (cus) => {    
      setis_edit(true)   
      setid(cus.id)    
      setcus_name(cus.cus_name)
      setdob(moment(cus.dob).format("DD-MM-YYYY"))
      setgender(cus.gender)
      setphone(cus.phone)
      setplace(cus.place)
      setprivilege_id(cus.privilege_id)
     
      window.scrollTo({
        top: 100,
        behavior: 'smooth',
      })
    }

    const handleClear = () => {     
      // console.log('------clear-------')
      setis_edit(false)   
      setcus_name('')
      setdob('')
      setgender('')
      setphone('')
      setplace('')
      setprivilege_id('')    
    }
    

    const handleDelete = (pur) => {
        console.log('####',pur.id)
        var option = window.confirm(`Are You Sure want to delete ${pur.cus_name}`);
        if (option) {
                dispatch(deleteCustomer(pur.id))
                .then(res=>{  
                 dispatch(allCustomersClear())
                 dispatch(getAllCustomer(1,itemsPerPage))
                 handleClear()
                })
       }
      };

      const handleSearch = (val) => {
        setfrom_date('');
        setto_date('');
        setsearch(val)
        dispatch(searchCustomer(val)).then((res)=>{
          setData(res.response.data.results)
        }).catch(err=>{
          setsearch('')
          dispatch(getAllCustomer(1,itemsPerPage))
        })
      }
   
        


     const Submit = () =>{
        const user_data = {id, cus_name,dob,gender, phone, place, privilege_id }
        console.log('000000',user_data)
        const errors = validate(user_data);
        seterrors(errors)
        console.log('@@@@@',errors)
        if (Object.keys(errors).length === 0) {
          if(!is_edit){
            dispatch(addCustomer(user_data))
            .then(res=>{
                dispatch(getAllCustomer(1,itemsPerPage))
                handleClear()
            })
          }else{
            dispatch(updateCustomer(user_data))
            .then((res)=>{
              //console.log('!!!!!!',res.response.data.success)            
                setis_edit(false)
                handleClear()
                dispatch(getAllCustomer(currentPage,itemsPerPage))
                if(res.response.data.success){
                  alert('Updated')
                }
            })
          }

        
        }
        else{
        // console.log('@#@#@#@#',errors)
        }
     }   

     
    const validate = (data) => {
        const errors={};                       
          if (!data.cus_name) {
            errors.cus_name = "*"
          }      
          // if (!data.dob) {
          //   errors.dob = "*"
          // }    
          if (!data.gender) {
            errors.gender = "*"
          }   
          if (!data.phone) {
            errors.phone = "*"
          }  
          if (!data.place) {
            errors.place = "*"
          } 
                            
        return errors;
      };


      // ------------- Pagination start---------- //
      const [currentPage, setcurrentPage] = useState(1);
      const [itemsPerPage, setitemsPerPage] = useState(5);

      const [pageNumberLimit, setpageNumberLimit] = useState(5);
      const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
      const [minPageNumberLimit, setminPageNumberLimit] = useState(0);


      const [data, setData] = useState([]);

      const handleClick = (event) => {
        setcurrentPage(Number(event.target.id));
        dispatch(getAllCustomer((Number(event.target.id)),itemsPerPage))
      };

      const pages = [];
      for (let i = 1; i <= Math.ceil(tot / itemsPerPage); i++) {
       pages.push(i);
      }


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    //const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
      dispatch(getAllCustomer(currentPage,itemsPerPage))
    },[currentPage, itemsPerPage])

    const handleNextbtn = () => {
      setcurrentPage(currentPage + 1); 
      // dispatch(getAllPurchase(currentPage)) 
      if (currentPage + 1 > maxPageNumberLimit) {
        setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
        setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
      }
    };

    const handlePrevbtn = () => {
      setcurrentPage(currentPage - 1);  
      if ((currentPage - 1) % pageNumberLimit == 0) {
        setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
      }
    };

    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
      pageIncrementBtn = <li onClick={handleNextbtn}> &hellip;+ </li>;
    }
  
    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
      pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip;- </li>;
    }

    const renderPageNumbers = pages.map((number) => {
      if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
        return (
          <li
            key={number}
            id={number}
            onClick={handleClick}
            className={currentPage == number ? "active" : null}
          >
            {number}
          </li>
        );
      } else {
        return null;
      }
    });

    const handleLoadMore = () => {
      setitemsPerPage(itemsPerPage + 5);
      setmaxPageNumberLimit(5);
      setminPageNumberLimit(0);
      setcurrentPage(1)
    };
  

     // ------------- Pagination end---------- //

console.log('_____DATA______',data)
  return (
    <>
    <AdminNavbar />
    <br/>
    {/* <p style={{fontSize:'2.5rem'}} className="text-center">CUSTOMERS</p> */}
    <Section>
    <>
           {loading && (<Loading/>)}          
           {error && (<Error success="Something Went Wrong" />)}
           {success &&(<Success success="Inserted Successfully" />)}

    <ul className="wrapper">
      <li className="form-row">
       <label htmlFor="name">Customer Name</label>
       <TextField className='txtfld' id="outlined-basic" error={ errors.cus_name ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {setcus_name(e.target.value)}}
        value={cus_name}
        />
     </li>
     <li className="form-row">  
       <label htmlFor="name">DOB</label>    
            <div className="customDatePickerWidth">
              <DatePicker           
                  value={dob !== 'Invalid date' ? dob : ''}
                  onChange={(date)=>{ setdob(moment(date).format("DD-MM-YYYY"))}}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="dd-mm-yyyy"
                  monthsShown={1}
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  scrollableYearDropdown
                  maxDate={new Date()}
                />
                <br />
                { errors.dob && <OnLineErrors text={errors.dob} />}
            </div>                
        </li>

     <li className="form-row">
           <label htmlFor="gender">Gender</label>
             <div style={{marginRight:'160px'}}>
                   <RadioComponent
                      onGender={(val) => {setgender(val)                               
                      }}
                      val={gender}
                    />
                     <br />
                     <div style={{marginTop:'-1rem'}}>
                        { errors.gender && <OnLineErrors text={errors.gender} />}
                     </div>               
             </div>               
     </li>

     <li className="form-row">
       <label htmlFor="name">phone</label>
       <TextField className='txtfld' id="outlined-basic" error={ errors.phone ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {setphone(e.target.value)}}
        value={phone}
       />   
     </li>

     
    

     <li className="form-row">
       <label htmlFor="name">Place</label>
       <TextField className='txtfld' id="outlined-basic" error={ errors.place ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {setplace(e.target.value)}}
        value={place}
        />
     </li>

     <li className="form-row">
       <label htmlFor="name">Privilege Id</label>
       <TextField className='txtfld' id="outlined-basic" error={ errors.privilege_id ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {setprivilege_id(e.target.value.toUpperCase())}}
        value={privilege_id}
        />
     </li>
    
    
     <br/>
     <li className="form-row">
     <Button variant="contained" color="primary" onClick={Submit}> {is_edit === false ? 'Submit': 'Update'} </Button>         
     </li>
   </ul>

 </>
     </Section>
     <ViewCustomers 
        customer={data} 
        search={search}
        from_date={from_date}
        to_date={to_date}
        cp={currentPage} 
        onHandleUpdate={handleUpdate}
        onHandleDelete={handleDelete}
        onHandleSearch={handleSearch}
      
     /> 
    {
  search.length>0 || from_date.length > 0 ? 
  null
  :
  <>
     <div className="pageNumbers">    
         <button
            onClick={handlePrevbtn}
            disabled={currentPage == pages[0] ? true : false}
          >   &lt;&lt;
          </button>
          {pageDecrementBtn} 
          {renderPageNumbers}       
          {pageIncrementBtn}
        <button
            onClick={handleNextbtn}
            disabled={currentPage == pages[pages.length - 1] ? true : false}
          >   &gt;&gt;
        </button>  
    </div> 
    <div style={{display:'flex',justifyContent:'center', margin:'20px 50px'}}>
     <button onClick={handleLoadMore} className="loadmore">
        Load More
      </button>
    </div>      
    </>
}   
     </>
  )
}


const Section = styled.section`

margin:50px auto;
max-width:650px !important;
 .wrapper {
    background-color: #ffffff;
    border:1px solid #036440;
    list-style-type: none;
    padding: 10px 50px;
    border-radius: 10px;   
  }

   
  .form-row {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;

  }
  .form-row > label {
    padding: .5em 1em .5em 0;
    flex: 1;
    color:#036440;
  }  

 
  .form-row .txtfld {
    flex: 2;
  }


  @media screen and (min-width: 280px) and (max-width: 720px) {
    .form-row{
      display: flex;
      flex-direction: column;
      }
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    .form-row{
      display: flex;
      flex-direction: column;
      }
  }

  
`;


