import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as moment from "moment";
import { TextField,Button } from '@material-ui/core';
import DatePicker from "react-datepicker";

export default function ViewService(props) {
  return (
    <>
      <Section>
      <div className='view__acc_container'>   
      <h1>SERVICES</h1>
            <div style={{textAlign:'left'}}>
              <TextField className='txtfld' id="outlined-basic"   variant="outlined"  fullWidth={false}
                onChange={(e) => {props.onHandleSearchService(e.target.value)}}
                value={props.search}
                placeholder="Search"
                style={{backgroundColor:'white'}}
              />
              </div>
         <br/>
  
          <table className="table table-bordered table-responsive-sm">
              <thead style={{backgroundColor:'#ff8c00', color:'#ffffff'}}>
                  <tr>
                      <th>S.No</th>
                      <th>Entry Date</th>  
                      <th>Service Name</th>                                      
                      <th>Category</th>  
                      <th>Regular Rate</th>   
                      <th>Member Rate</th>                        
                     
                  </tr>
              </thead>
              <tbody>
        {props.service.length > 0 ? 
        props.service.map((ser, index) => {
          return (        
              <tr key= {index} onClick={()=>{props.onHandleEditService(ser)}}>
                  <td> {(index+1)} </td>
                  {/* <td> {moment(pur.entry_date).format("DD-MM-YYYY")} </td> */}
                  <td> {moment(ser.entry_date).format("DD-MM-YYYY")} </td>
                  <td> {ser.service_name} </td>                  
                  <td> {ser.category} </td>                      
                  <td> {ser.regular_rate} </td>               
                  <td> {ser.member_rate} </td>    
                
              </tr>
          );
          })
        :<tr><td>No Data</td></tr>
        }
                    
              </tbody>

          </table>

      </div>    
      </Section>
    </>
  )
}


const Section = styled.section`
.view__acc_container{
  margin:50px 50px;
}

.view__filterbox__container{
      margin:20px auto;
      display: flex;
      /* justify-content: space-between; */
      align-items: center;
      gap: 1rem;   
      padding:1rem;  
      background-color: #b9ecfd
;
}
.view__filterdate__container{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;        
      flex:0.7;
}
.view__filtersearch__container{  
  flex:0.3;
  text-align:center;
}

@media screen and (min-width: 280px) and (max-width: 720px) {
    
    .view__filterbox__container{
      display: flex;
      flex-direction: column;
      }
    .view__filterdate__container{
      display: flex;
      flex-direction: column;
    }
  }

`;