import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {loginUser} from '../../redux/actions/userActions';
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import './Login.css';
import logo from '../../assets/logo.png'
import bg from '../../assets/bg.png'

export default function Login() {

  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");

  const loginstate = useSelector(state=>state.loginUserReducer);
  const {error, loading} = loginstate

  const dispatch = useDispatch()

  useEffect(()=>{
     
  },[])


  const login = ()=>{      
          const user = {             
              username,
              password
          }        
          dispatch(loginUser(user))      
  }

  return (
    <div style={{backgroundImage: `url(${bg})`, height:'100vh',backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}>
      <div className="row justify-content-center" >
        <div className="col-md-4 col-sm-10  text-left shadow-lg p-3 mb-5  rounded" style={{marginTop:'10rem', backgroundColor:'#ffecf2'}}>

           {loading && (<Loading/>)}           
           {error && (<Error error="Invalid Credentials" />)}
         
          <h2 className="text-center m-2" style={{ fontSize: "35px" }}>
          <img src={logo} style={{height:'8rem', width:'10rem'}}/><br></br>Login
          </h2>
          <br/>
          <div>
           
           <div style={{width:'80%',paddingLeft:'20%'}}>
            <input type="text"
              required
              placeholder="Username"
              className="form-control"
              value={username}
              onChange={(e) => {setusername(e.target.value)}}
              />
           </div>
           <br/>
           <div style={{marginTop:'1rem',width:'80%',paddingLeft:'20%'}}>
            <input
              type="password"
              required
              placeholder="Password"
              className="form-control"
              value={password}
              onChange={(e) => {setpassword (e.target.value)}}
            />
            </div>
            <div style={{marginTop:'1rem',width:'100%',paddingLeft:'40%'}}>
            <button onClick={login} className="btn mt-3 mb-3">LOGIN</button>
            </div>
            <br></br>
            
           
          </div>
        </div>
      </div>
    </div>
  );
}
