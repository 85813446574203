import axios from 'axios';
import { apiUrl } from "../../config";


export const addBillService = (acc)=>async dispatch=>{
console.log('ACTION------>',acc)
       dispatch({type:'ADD_BILLSERVICE_REQUEST'})
       try {
           const response = await axios.post(`${apiUrl}/api/billservice/create`,acc)
           //console.log('+++ add',response)
           dispatch({type:'ADD_BILLSERVICE_SUCCESS'})
           return{
               response
           }
          
       } catch (error) {
           dispatch({type:'ADD_BILLSERVICE_FAILED', payload:error}) 
       }
   }

export const allBillServiceClear = (acc)=>async dispatch=>{
    try {
      
        dispatch({type:'ALL_BILLSERVICE_CLEAR'})
    } catch (error) {
        dispatch({type:'ALL_BILLSERVICE', payload:error}) 
    }
}   


export const getAllBillServiceLength=()=>async dispatch=>{
try{
    const response =await axios.get(`${apiUrl}/api/billservice/getallbillservice_length`)    
    //console.log('%%%111%%%',response.data.results[0].tot);
   return{  response  }  
} catch(error){   
}
}

export const getAllBillService=(offset,row_count)=>async dispatch=>{
   
    dispatch({type:'GET_BILLSERVICE_REQUEST'})

try{
    const response =await axios.get(`${apiUrl}/api/billservice/getallbillservice/${offset}/${row_count}`)    
    //console.log('%%%%%%',response);
    dispatch({type:'GET_BILLSERVICE_SUCCESS',payload:response.data.results})
} catch(error){
    //console.log('EEEEEEEE',error);
    dispatch({type:'GET_BILLSERVICE_FAILED', payload:error})
}
}

export const getAllBillServicebycus=(offset,row_count,cus_name, phone, from_date)=>async dispatch=>{
    //console.log('GGGGGGG',from_date)
    dispatch({type:'GET_BILLSERVICE_REQUEST'})

try{
    const response =await axios.get(`${apiUrl}/api/billservice/getallbillservicebycus/${offset}/${row_count}/${cus_name}/${phone}/${from_date}`)    
    //console.log('%%%%%%',response);
    dispatch({type:'GET_BILLSERVICE_SUCCESS',payload:response.data.results})
} catch(error){
    //console.log('EEEEEEEE',error);
    dispatch({type:'GET_BILLSERVICE_FAILED', payload:error})
}
}



export const deleteBillService = (accid)=>async dispatch=>{
    try {
        const response = await axios.post(`${apiUrl}/api/billservice/delete`,{accid})    
        return{  response  }
       // window.location.reload()
    } catch (error) {
        alert('Something Went Wrong')
        console.log(error)
    }
}

export const getBill=(id,cus_name)=>async dispatch=>{
    dispatch({type:'GET_BILLSERVICE_REQUEST'})

try{
    const response =await axios.get(`${apiUrl}/api/sales/getbill/${id}/${cus_name}`)    
    console.log('%%%%%%',response);
    dispatch({type:'GET_BILLSERVICE_SUCCESS',payload:response.data.results})
} catch(error){
    dispatch({type:'GET_BILLSERVICE_FAILED', payload:error})
}
}

export const searchBillService = (val)=>async dispatch=>{
    const data={id:val}
    try {
        const response = await axios.post(`${apiUrl}/api/billservice/search`,data)          
        return{  response  }
       // window.location.reload()
    } catch (error) {
        return{  error  }
        //alert('Something Went Wrong')
        console.log(error)
    }
}

export const filterBillService = (data)=>async dispatch=>{
    try {
        const response = await axios.post(`${apiUrl}/api/billservice/filterdate`,data)          
        return{  response  }        
       // window.location.reload()
    } catch (error) {
        return{  error  }
        //alert('Something Went Wrong')
        console.log(error)
    }
}


export const CheckedBillService = (id,val)=>async dispatch=>{
    const data={id,val}
    try {
        const response = await axios.post(`${apiUrl}/api/billservice/checked`,data)          
        return{  response  }
       // window.location.reload()
    } catch (error) {
        return{  error  }
        //alert('Something Went Wrong')
        console.log(error)
    }
}
