import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextField,Button } from '@material-ui/core';
import * as moment from "moment";
import AdminNavbar from '../../components/AdminNavbar/AdminNavbar'
import {useDispatch, useSelector} from 'react-redux';
import jwt_decode from "jwt-decode";
import Select from 'react-select';
import ViewPurchase from './ViewPurchase';
import {addPurchase,deletePurchase,updatePurchase, getAllPurchase,getAllPurchaseLength, allPurchaseClear, searchPurchase,filterPurchase} from '../../redux/actions/purchaseAction';
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import OnLineErrors from '../../components/OnLineErrors';
import Success from "../../components/Success";
import './pagestyle.css'


export default function Purchase() {     

    const [is_edit, setis_edit] = useState(false);
    const [id, setid] = useState("");
    const [pur_date, setpur_date] = useState("");
    const [pro_name, setpro_name] = useState("");   
    const [description, setdescription] = useState("");
    const [quantity, setquantity] = useState("");
    const [category, setcategory] = useState("");
    const [category_val, setcategory_val] = useState("");
    const [bill_amount, setbill_amount] = useState("");
    const [gst_amount, setgst_amount] = useState("0");

    const [errors, seterrors] = useState([]);
    const [search, setsearch] = useState('');
    const [tot, setTot] = useState(0);

    const [from_date, setfrom_date] = useState("");
    const [to_date, setto_date] = useState("");

    const options = [
      { value: 'General', label: 'General'},
      { value: 'Men', label: 'Men' },
      { value: 'Women', label: 'Women' },      
    ];
    const customStyles = {
      control: (base) => ({
        ...base,
        borderColor: errors.category ==='*' ? "red" : "gray",
      })  
    };

 

     const userState = useSelector(state=>state.loginUserReducer)
     const {currentUser} = userState;
    
     const add_purchase_state = useSelector(state=>state.getAddPurchaseReducer)    
     const {success, error, loading} = add_purchase_state;

     const all_purchase_state = useSelector(state=>state.getAllPurchaseReducer)
     const {purchase} =  all_purchase_state;

   
    
     useEffect(() => {           
        if(currentUser.length>0){             
            var decoded = jwt_decode(userState.currentUser);           
            if(decoded.role !== ('admin'))
            {  window.location.href="/sales"  }
            } else { window.location.href="/login" }
            setData(purchase)
             dispatch(allPurchaseClear())
             dispatch(getAllPurchaseLength()).then((response)=>{
              setTot(response.response.data.results[0].tot)
             })
             dispatch(getAllPurchase(1,itemsPerPage))
    },[])

    useEffect(() => {                    
          setData(purchase) 
    },[purchase])

    useEffect(() => { 
      if(search.length < 1){dispatch(getAllPurchase(currentPage,itemsPerPage))}
     },[search])
       
    const dispatch = useDispatch()


    const handleUpdate = (pur) => {    
      setis_edit(true)   
      setid(pur.id)
      setpur_date(moment(pur.pur_date).format("DD-MM-YYYY"))
      setpro_name(pur.pro_name)
      setdescription(pur.description)
      setquantity(pur.quantity)
      setcategory(pur.category)
      setcategory_val(pur.category)
      setbill_amount(pur.bill_amount)
      setgst_amount(pur.gst_amount)
      window.scrollTo({
        top: 100,
        behavior: 'smooth',
      })
    }

    const handleClear = () => {     
      // console.log('------clear-------')
      setis_edit(false)   
      setpur_date('')
      setpro_name('')
      setdescription('')
      setcategory('')
      setcategory_val('')
      setquantity('')
      setcategory('')
      setbill_amount('')
      setgst_amount('')
    }
    

    const handleDelete = (pur) => {
        console.log('####',pur.id)
        var option = window.confirm(`Are You Sure want to delete Bill Num ${pur.pro_name}`);
        if (option) {
                dispatch(deletePurchase(pur.id))
                .then(res=>{  
                 dispatch(allPurchaseClear())
                 dispatch(getAllPurchase(1,itemsPerPage))
                })
       }
      };

      const handleSearch = (val) => {
        setfrom_date('');
        setto_date('');
        setsearch(val)
        dispatch(searchPurchase(val)).then((res)=>{
          setData(res.response.data.results)
        }).catch(err=>{
          setsearch('')
          dispatch(getAllPurchase(1,itemsPerPage))
        })
      }

      const handleSerachdate = ()=>{   
        if (from_date !== '' && to_date !=='') {
          setsearch('');
          const data = {from_date, to_date}   
          dispatch(filterPurchase(data)).then((res)=>{
            console.log('------ RP ------',res.response.data.results)
            setData(res.response.data.results)
          }).catch(err=>{      
            dispatch(getAllPurchase(1,itemsPerPage))
          })
        }           
        else {
          alert('Select date')
        }  
      }  
        


     const Submit = () =>{
        const user_data = {id, pur_date, pro_name, description, quantity, category, bill_amount }
        console.log('000000',user_data)
        const errors = validate(user_data);
        seterrors(errors)
        console.log('@@@@@',errors)
        if (Object.keys(errors).length === 0) {
          if(!is_edit){
            dispatch(addPurchase(user_data))
            .then(res=>{
                dispatch(getAllPurchase(1,itemsPerPage))
                console.log('----RRR -----', res)
                alert(res.response.data.msg)
                handleClear();
            })
          }else{
            dispatch(updatePurchase(user_data))
            .then((res)=>{
              //console.log('!!!!!!',res.response.data.success)            
                setis_edit(false)
                handleClear()
                dispatch(getAllPurchase(currentPage,itemsPerPage))
                if(res.response.data.success){
                  alert('Updated')
                }
            })
          }

        
        }
        else{
        // console.log('@#@#@#@#',errors)
        }
     }   

     
    const validate = (data) => {
        const errors={};  
         
          if (!data.pur_date) {
            errors.pur_date = "*"
          }    
          if (!data.pro_name) {
            errors.pro_name = "*"
          }            
          if (!data.description) {
            errors.description = "*"
          }  
          if (!data.quantity) {
            errors.quantity = "*"
          } 
          if (!data.category) {
            errors.category = "*"
          }  
          if (!data.bill_amount) {
            errors.bill_amount = "*"
          }          
          // if (!data.gst_amount) {
          //   errors.gst_amount = "*"
          // } 
             
        return errors;
      };


      // ------------- Pagination start---------- //
      const [currentPage, setcurrentPage] = useState(1);
      const [itemsPerPage, setitemsPerPage] = useState(5);

      const [pageNumberLimit, setpageNumberLimit] = useState(5);
      const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
      const [minPageNumberLimit, setminPageNumberLimit] = useState(0);


      const [data, setData] = useState([]);

      const handleClick = (event) => {
        setcurrentPage(Number(event.target.id));
        dispatch(getAllPurchase((Number(event.target.id)),itemsPerPage))
      };

      const pages = [];
      for (let i = 1; i <= Math.ceil(tot / itemsPerPage); i++) {
       pages.push(i);
      }


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    //const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
      dispatch(getAllPurchase(currentPage,itemsPerPage))
    },[currentPage, itemsPerPage])

    const handleNextbtn = () => {
      setcurrentPage(currentPage + 1); 
      // dispatch(getAllPurchase(currentPage)) 
      if (currentPage + 1 > maxPageNumberLimit) {
        setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
        setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
      }
    };

    const handlePrevbtn = () => {
      setcurrentPage(currentPage - 1);  
      if ((currentPage - 1) % pageNumberLimit == 0) {
        setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
      }
    };

    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
      pageIncrementBtn = <li onClick={handleNextbtn}> &hellip;+ </li>;
    }
  
    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
      pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip;- </li>;
    }

    const renderPageNumbers = pages.map((number) => {
      if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
        return (
          <li
            key={number}
            id={number}
            onClick={handleClick}
            className={currentPage == number ? "active" : null}
          >
            {number}
          </li>
        );
      } else {
        return null;
      }
    });

    const handleLoadMore = () => {
      setitemsPerPage(itemsPerPage + 5);
      setmaxPageNumberLimit(5);
      setminPageNumberLimit(0);
      setcurrentPage(1)
    }; 

     // ------------- Pagination end---------- //


  return (
    <>
    <AdminNavbar />
    <br/>
    {/* <p style={{fontSize:'2.5rem'}} className="text-center">PRODUCTS</p> */}
    <Section>
    <>
         
           {loading && (<Loading/>)}          
           {error && (<Error success="Something Went Wrong" />)}
           {success &&(<Success success="Inserted Successfully" />)}
         

    <ul className="wrapper">
      

    <li className="form-row">  
       <label htmlFor="name">Purchase Date</label>    
            <div className="customDatePickerWidth">
              <DatePicker           
                  value={pur_date !== 'Invalid date' ? pur_date : ''}
                  onChange={(date)=>{ setpur_date(moment(date).format("DD-MM-YYYY"))}}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="dd-mm-yyyy"
                  monthsShown={1}
                  showYearDropdown
                />
                   { errors.pur_date && <OnLineErrors text={errors.pur_date} />}
            </div>                
        </li>
             
      <li className="form-row">
       <label htmlFor="name">Product Name</label>
       <TextField className='txtfld' id="outlined-basic" error={ errors.pro_name ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {setpro_name(e.target.value)}}
        value={pro_name}
        />
     </li>

     <li className="form-row">
       <label htmlFor="name">Description</label>
       <TextField className='txtfld' id="outlined-basic" error={ errors.description ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {setdescription(e.target.value)}}
        value={description}
       />   
     </li>

     <li className="form-row">
       <label htmlFor="name">Category</label>
       <div style={{width:'65%'}}>
           <Select
             value={category_val}
             onChange={(e)=>{setcategory_val(e);setcategory(e.value)}}                                 
             options={options}
             placeholder={is_edit ? category : 'Select...'}
             styles={customStyles}
            />              
       </div>      
     </li>
    

     <li className="form-row">
       <label htmlFor="name">Quantity</label>
       <TextField className='txtfld' id="outlined-basic" error={ errors.quantity ? true : false}  type='number'   variant="outlined"  fullWidth={false}
        onChange={(e) => {setquantity(e.target.value)}}
        value={quantity}
        />
     </li>
    
     <li className="form-row">
       <label htmlFor="name">Amount</label>
       <TextField className='txtfld' id="outlined-basic" error={ errors.bill_amount ? true : false} type="number"   variant="outlined"  fullWidth={false}
        onChange={(e) => {setbill_amount(e.target.value)}}
        value={bill_amount}
        />
     </li>

     {/* <li className="form-row">
       <label htmlFor="name">GST Amount</label>
       <TextField className='txtfld' id="outlined-basic" error={ errors.gst_amount ? true : false} type="number"   variant="outlined"  fullWidth={false}
        onChange={(e) => {setgst_amount(e.target.value)}}
        value={gst_amount}
        />
     </li> */}
     <br/>
     <li className="form-row">
     <Button variant="contained" color="primary" onClick={Submit}> {is_edit === false ? 'Submit': 'Update'} </Button>         
     </li>
   </ul>

 </>
     </Section>
     <ViewPurchase 
        purchase={data} 
        search={search}
        from_date={from_date}
        to_date={to_date}
        cp={currentPage} 
        onHandleUpdate={handleUpdate}
        onHandleDelete={handleDelete}
        onHandleSearch={handleSearch}
        onHandleFromDate={(date)=>{ setfrom_date(moment(date).format("DD-MM-YYYY"))}}
        onHandleToDate={(date)=>{ setto_date(moment(date).format("DD-MM-YYYY"))}}
        onHandleSearchDate={handleSerachdate}
     />
    {
  search.length>0 || from_date.length > 0 ? 
  null
  :
  <>
     <div className="pageNumbers">    
         <button
            onClick={handlePrevbtn}
            disabled={currentPage == pages[0] ? true : false}
          >   &lt;&lt;
          </button>
          {pageDecrementBtn} 
          {renderPageNumbers}       
          {pageIncrementBtn}
        <button
            onClick={handleNextbtn}
            disabled={currentPage == pages[pages.length - 1] ? true : false}
          >   &gt;&gt;
        </button>  
    </div> 
    <div style={{display:'flex',justifyContent:'center', margin:'20px 50px'}}>
     <button onClick={handleLoadMore} className="loadmore">
        Load More
      </button>
    </div>      
    </>
}   
     </>
  )
}


const Section = styled.section`

margin:50px auto;
max-width:650px !important;
 .wrapper {
    background-color: #ffffff;
    border:1px solid #3B2774;
    list-style-type: none;
    padding: 10px 50px;
    border-radius: 10px;   
  }

   
  .form-row {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;

  }
  .form-row > label {
    padding: .5em 1em .5em 0;
    flex: 1;
    color:#3B2774;
  }  

 
  .form-row .txtfld {
    flex: 2;
  }


  @media screen and (min-width: 280px) and (max-width: 720px) {
    .form-row{
      display: flex;
      flex-direction: column;
      }
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    .form-row{
      display: flex;
      flex-direction: column;
      }
  }

  
`;


