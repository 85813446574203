import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as moment from "moment";
import { TextField,Button } from '@material-ui/core';
import DatePicker from "react-datepicker";

export default function ViewCustomer(props) {
  return (
    <>
      <Section>
      <div className='view__acc_container'>     
        <h1 style={{textAlign:'left'}}>Customers List</h1>
        
        <div style={{textAlign:'left'}}>
        <TextField className='txtfld' id="outlined-basic"   variant="outlined"  fullWidth={false}
                onChange={(e) => {props.onHandleSearchCusData(e.target.value)}}
                value={props.search}
                placeholder="Search"
                style={{backgroundColor:'white'}}
                
              />
        </div>
        <br />
        <div style={{height: '100%', overflowY: 'auto'}}>
          <table className="table table-bordered table-responsive-sm">
              <thead style={{backgroundColor:'#7fbf7f', color:'#ffffff'}}>
                  <tr>
                      <th>S.No</th>
                      <th>Name</th>                                      
                      <th>Phone </th>  
                      <th>Place</th>                  
                  </tr>
              </thead>
              <tbody>
        {props.customer.length > 0 ? 
        props.customer.map((pur, index) => {
          return (        
              <tr key= {index}  onClick={()=>{props.onHandleEdit(pur)}}>
                  <td> {(index+1)} </td>                 
                  <td> {pur.cus_name} </td>
                  <td> {pur.phone} </td>     
                  <td> {pur.place} </td>   
              </tr>
          );
          })
        :<tr><td>No Data</td></tr>
        }
                    
              </tbody>

          </table>
          </div>
      </div>    
      </Section>
    </>
  )
}


const Section = styled.section`
.view__acc_container{
  margin:50px 50px;
}

.view__filterbox__container{
      margin:20px auto;
      display: flex;
      /* justify-content: space-between; */
      align-items: center;
      gap: 1rem;   
      padding:1rem;  
      background-color: #FBc1c1;
}
.view__filterdate__container{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;        
      flex:0.7;
}
.view__filtersearch__container{  
  flex:0.3;
  text-align:center;
}

@media screen and (min-width: 280px) and (max-width: 720px) {
    
    .view__filterbox__container{
      display: flex;
      flex-direction: column;
      }
    .view__filterdate__container{
      display: flex;
      flex-direction: column;
    }
  }

`;