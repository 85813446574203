

export const getAddSalesReducer=(state={}, action)=>{

    switch(action.type)
    {
        case 'ADD_SALES_REQUEST': return{
            loading:true,
            ...state
        }
        case 'ADD_SALES_SUCCESS': return{
            loading:false,
            success:true
        }
        case 'ADD_SALES_FAILED': return{
            loading:false,
            error:action.payload
        }
        
        case 'ALL_SALES_CLEAR': return{
            loading:false,
            success:false
        }
        
        default : return state
    }
}

export const getAllSalesReducer=(state={sales:[]}, action)=>{

    switch(action.type)
    {
        case 'GET_SALES_REQUEST': return{
            loading:true,
            ...state
        }
        case 'GET_SALES_SUCCESS': return{
            loading:false,
            sales:action.payload
        }
        case 'GET_SALES_FAILED': return{
            loading:false,
            error:action.payload
        }
        default : return state
    }
}