import axios from 'axios';
import { apiUrl } from "../../config";


export const addService = (acc)=>async dispatch=>{
console.log('ACTION------>',acc)
       dispatch({type:'ADD_SERVICE_REQUEST'})
       try {
           const response = await axios.post(`${apiUrl}/api/service/create`,acc)
           console.log('+++ add',response)
           dispatch({type:'ADD_SERVICE_SUCCESS'})
           return{
               response
           }
          
       } catch (error) {
           dispatch({type:'ADD_SERVICE_FAILED', payload:error}) 
       }
   }



export const allServiceClear = (acc)=>async dispatch=>{
    try {
      
        dispatch({type:'ALL_SERVICE_CLEAR'})
    } catch (error) {
        dispatch({type:'ALL_SERVICE_FAILED', payload:error}) 
    }
}   


export const getAllServiceLength=()=>async dispatch=>{
try{
    const response =await axios.get(`${apiUrl}/api/service/getallservice_length`)    
    //console.log('%%%111%%%',response.data.results[0].tot);
   return{  response  }  
} catch(error){   
}
}

export const getAllService=(offset,row_count)=>async dispatch=>{
    dispatch({type:'GET_SERVICE_REQUEST'})

try{
    const response =await axios.get(`${apiUrl}/api/service/getallservice/${offset}/${row_count}`)    
   // console.log('%%%%%%',response);
    dispatch({type:'GET_SERVICE_SUCCESS',payload:response.data.results})
} catch(error){
    dispatch({type:'GET_SERVICE_FAILED', payload:error})
}
}

export const updateService = (ser)=>async dispatch=>{
   
    try {
        const response = await axios.post(`${apiUrl}/api/service/update`,ser)    
        return{  response  }
       // window.location.reload()
    } catch (error) {
        alert('Something Went Wrong')
        console.log(error)
    }
}

export const deleteService = (accid)=>async dispatch=>{
    try {
        const response = await axios.post(`${apiUrl}/api/service/delete`,{accid})    
        return{  response  }
       // window.location.reload()
    } catch (error) {
        alert('Something Went Wrong')
        console.log(error)
    }
}


export const searchService = (val)=>async dispatch=>{
    const data={id:val}
    try {
        const response = await axios.post(`${apiUrl}/api/service/search`,data)          
        return{  response  }
       // window.location.reload()
    } catch (error) {
        return{  error  }
        //alert('Something Went Wrong')
        console.log(error)
    }
}

export const filterService = (data)=>async dispatch=>{
    try {
        const response = await axios.post(`${apiUrl}/api/service/filterdate`,data)          
        return{  response  }        
       // window.location.reload()
    } catch (error) {
        return{  error  }
        //alert('Something Went Wrong')
        console.log(error)
    }
}
