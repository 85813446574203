

export const getAddServiceReducer=(state={}, action)=>{

    switch(action.type)
    {
        case 'ADD_SERVICE_REQUEST': return{
            loading:true,
            ...state
        }
        case 'ADD_SERVICE_SUCCESS': return{
            loading:false,
            success:true
        }
        case 'ADD_SERVICE_FAILED': return{
            loading:false,
            error:action.payload
        }        
        case 'ALL_SERVICE_CLEAR': return{
            loading:false,
            success:false
        }
        
        default : return state
    }
}

export const getAllServiceReducer=(state={service:[]}, action)=>{

    switch(action.type)
    {
        case 'GET_SERVICE_REQUEST': return{
            loading:true,
            ...state
        }
        case 'GET_SERVICE_SUCCESS': return{
            loading:false,
            service:action.payload
        }
        case 'GET_SERVICE_FAILED': return{
            loading:false,
            error:action.payload
        }
        default : return state
    }
}